@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.tooltipContainer {
  background-color: rgba(#252525,0.85);
  backdrop-filter: blur(12px);
  border-radius: 0.5em;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.18);
  color: #fff;
  display: block;
  flex-direction: column;
  padding: 0.5rem 1rem;
  z-index: 9999;
  font-size: 12px;
  max-width: 220px;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.15s;
  text-align: center;
  // filter: drop-shadow(0 0 1px #fff);
  text-shadow: 1px 1px 2px rgba(#000,0.1);
}

.tooltipContainer[data-popper-interactive='false'] {
  pointer-events: none;
}

.tooltipArrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.tooltipArrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltipContainer[data-popper-placement*='bottom'] .tooltipArrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}
.tooltipContainer[data-popper-placement*='bottom'] .tooltipArrow::after {
  border-color: transparent transparent rgba(#252525,0.85) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}
.tooltipContainer[data-popper-placement*='top'] .tooltipArrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}
.tooltipContainer[data-popper-placement*='top'] .tooltipArrow::after {
  border-color: rgba(#252525,0.85) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
.tooltipContainer[data-popper-placement*='right'] .tooltipArrow {
  left: 0;
  margin-left: -0.7rem;
}
.tooltipContainer[data-popper-placement*='right'] .tooltipArrow::after {
  border-color: transparent rgba(#252525,0.85) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.tooltipContainer[data-popper-placement*='left'] .tooltipArrow {
  margin-right: -0.7rem;
  right: 0;
}
.tooltipContainer[data-popper-placement*='left'] .tooltipArrow::after {
  border-color: transparent transparent transparent rgba(#252525,0.85);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}