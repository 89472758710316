@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
* {
  box-sizing: border-box;
}
html, body, #root, #defaultLayout, #guestLayout {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #212121;
  background-color: #f6f6f6;
}

.dropdown img {
  width: 38px;
  min-width: 38px;
  border-radius: 100%;
  border: #c2afd9 2px solid;
}

.dropdown-menu {
  inset: 1.75rem 0 auto auto !important;
}

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background: #252525;
  color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  min-height: 3rem;
}

.navbar button {
  background: none;
  border: none;
  cursor: pointer;
}

.navbar button i {
  font-size: 1.5rem;
  color: #e6e6e6;
  font-weight: 600;
}

#defaultLayout {
  min-height: calc(100vh - 4.4rem)
}

#defaultLayout aside {
  width: 240px;
  background-color: #252525;
  padding: 1rem
}

#defaultLayout aside > a {
  display: block;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: all 0.2s;
}

#defaultLayout aside > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

#defaultLayout .content {
  flex: 1;
}

#defaultLayout header {
  height: 80px;
  padding: 2rem 2rem;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

#defaultLayout main {
  padding: 2rem;
}

input {
  outline: 0;
  background: #ffffff;
  width: 100%;
  border: 2px solid #e6e6e6;
  border-radius: 8px;
  margin: 0 0 10px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.3s;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
}

input:focus {
  border-color: #051346;
}

.login-signup-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-signup-form .form {
  border-radius: 5px;
  width: 460px;
  max-width: 460px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  padding: 24px;
  margin: auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.login-signup-form .title {
  font-size: 20px;
  margin-bottom: 1rem;
  text-align: center;
}

.login-signup-form .form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 16px;
  text-align: center;
}

.login-signup-form .form .message a {
  color: #051346;
  text-decoration: none;
}

.btn-login {
  font-family: "Nunito", sans-serif;
  outline: 0;
  background: #051346;
  border: 0;
  border-radius: 8px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.btn-login:hover,
.btn-login:active{
  color: #FFFFFF !important;
  background: #0a1c65 !important;
}

.btn-block {
  width: 100%;
}

#defaultLayout {
  display: flex;
}

#defaultLayout aside {
  width: 240px;
  background-color: #252525;
  padding: 1rem
}

#defaultLayout aside > a {
  display: block;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: all 0.2s;
}

#defaultLayout aside > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

#defaultLayout .content {
  flex: 1;
}

/* Estilo da barra de rolagem */
::-webkit-scrollbar {
  width: 8px;
}

/* Cor de fundo da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Cor do preenchimento da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Estilo quando hover na barra de rolagem */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}




