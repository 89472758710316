

@media screen and (min-width: 991px){
    .logo{
        position: absolute;
        left: 70px;
        top: 24%;
    }

    .profile{
        position: absolute;
        right: 15px;
        top: 24%;
    }

    
}